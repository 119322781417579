import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-title">&#169; The Star 2021 </div>
      <div className="footer-title">Wayne Mutai</div>
    </div>
  );
};

export default Footer;
