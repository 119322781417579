import React from 'react';

interface Section {
  id: string;
  link: string;
  name: string;
}

interface Image {
  filepath: string;
}

//* Structure of an Article
export interface Article {
  key: number;
  title: string;
  pub_url: string;
  image: Image;
  section: Section;
}

interface Props {
  articleInfo: Article;
}

const imagePrefix = 'https:';

/**
 * Displays a single Article
 */
const ArticleCard: React.FC<Props> = ({articleInfo}) => {
  const navigateToArticleSite = () => {
    const articleLink = 'https://the-star.co.ke' + articleInfo.pub_url;
    //*Open the Link in a new tab
    const newWindow = window.open(articleLink, '_blank', 'noopener,noreferrer'); //* noopener,noreferrer to prevent phishing
    if (newWindow) newWindow.opener = null; //* Resetting the opening property
  };

  return (
    <div className="article" onClick={navigateToArticleSite}>
      {articleInfo.image != null ? (
        <img
          src={imagePrefix + articleInfo.image.filepath}
          alt="photo"
          className="article-image"
        />
      ) : (
        //* Placeholder image incase none is available
        <img
          src={
            'https://media.tarkett-image.com/large/TH_24567080_24594080_24596080_24601080_24563080_24565080_24588080_001.jpg'
          }
          alt="photo"
          className="article-image"
        />
      )}
      <div className="article-description">
        <div className="article-title">{articleInfo.title}</div>
        <div className="article-section">
          <div className="article-section-text">{articleInfo.section.name}</div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
