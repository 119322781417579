import axios from 'axios';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {useForm} from '../hooks/useForm';
import {Article} from './ArticleCard';
const API_URL = process.env.REACT_APP_API_URL;

export interface Params {
  section?: string;
  subsection?: string;
  publication?: string;
  query?: string;
  page: number;
  limit?: number;
  trim_featured_list?: boolean;
}

interface Props {
  setParams: Dispatch<SetStateAction<Params>>;
  setArticles: Dispatch<SetStateAction<Article[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setIsResponseEmpty: Dispatch<SetStateAction<boolean>>;
  setArticleLimit: Dispatch<SetStateAction<number>>;
  articleLimit: number;
  setOffset: (
    state: Partial<{offset: number}>,
    cb: (state: {offset: number}) => void,
  ) => void;
}

const Filters: React.FC<Props> = ({
  setLoading,
  setArticles,
  setParams,
  setCurrentPage,
  setIsResponseEmpty,
  setArticleLimit,
  articleLimit,
  setOffset,
}) => {
  //*States for API parameters
  const [page, setPage] = useState(1);
  const [trimFeaturedList, setTrimFeaturedList] = useState(true);

  const [values, handleChange] = useForm({
    publication: '',
    section: '',
    query: '',
    subsection: '',
  });

  /**
   * Takes User parameters and queries the API for a reult
   */
  const FilterArticles = async () => {
    setLoading(true);
    //* Sets parameter state with the user's query
    setParams({
      publication: values.publication,
      section: values.section,
      subsection: values.subsection,
      query: values.query,
      page: page,
      limit: articleLimit,
      trim_featured_list: trimFeaturedList,
    });
    axios
      .get(API_URL!, {
        params: {
          publication: values.publication.replace(/\s/g, '-').toLowerCase(),
          section: values.section.replace(/\s/g, '-').toLowerCase(),
          subsection: values.subsection.replace(/\s/g, '-').toLowerCase(),
          query: values.query,
          page: page,
          limit: articleLimit,
          trim_featured_list: trimFeaturedList,
        },
      })
      .then(response => {
        if (response == null) throw new Error("Couldn't retrieve new articles");
        setLoading(false);
        //*Fills the filter results into State
        setArticles(response.data);
        //*Reset the offset
        setOffset({offset: 0}, () => console.log('filtered'));
        if (response.data.length > 0) {
          setIsResponseEmpty(false);
          setCurrentPage(1);
          return;
        }
        //* User received no articles
        setIsResponseEmpty(true);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="filter-container">
      <div className="filter">
        <h2 className="filter-title">Filter Articles</h2>
        <label className="filter-label">Publication</label>
        <input
          className="filter-input"
          type="text"
          name="publication"
          value={values.publication}
          onChange={handleChange}
        />
        <label className="filter-label">Section</label>
        <input
          className="filter-input"
          type="text"
          name="section"
          value={values.section}
          onChange={handleChange}
        />
        <label className="filter-label">Sub Section</label>
        <input
          className="filter-input"
          type="text"
          name="subsection"
          value={values.subsection}
          onChange={handleChange}
        />
        <label className="filter-label">Search</label>
        <input
          className="filter-input"
          type="text"
          name="query"
          value={values.query}
          onChange={handleChange}
        />
        <label className="filter-label">Page</label>
        <input
          className="filter-input"
          type="number"
          name="page"
          value={page}
          onChange={e => {
            if (isNaN(e.target.valueAsNumber) || e.target.valueAsNumber < 1) {
              setPage(1);
              return;
            }
            setPage(e.target.valueAsNumber);
          }}
        />
        <label className="filter-label">Limit</label>
        <input
          className="filter-input"
          type="number"
          name="limit"
          value={articleLimit}
          onChange={e => {
            if (isNaN(e.target.valueAsNumber) || e.target.valueAsNumber < 0) {
              setArticleLimit(0);
              return;
            }
            setArticleLimit(e.target.valueAsNumber);
          }}
        />
        <label className="filter-label">Trim Featured Content</label>
        <input
          className="filter-input"
          type="checkbox"
          name="trim-featured-content"
          checked={trimFeaturedList}
          onChange={e => setTrimFeaturedList(e.target.checked)}
        />
        <button className="filter-button" onClick={() => FilterArticles()}>
          Apply Filters
        </button>
      </div>
    </div>
  );
};

export default Filters;
