import React from 'react';
import star from '../assets/images/star.png';

const Header: React.FC = () => {
  return (
    <div className="header">
      <img src={star} alt="logo54" className="header-logo" />
    </div>
  );
};

export default Header;
