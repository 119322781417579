import React, {Dispatch, SetStateAction} from 'react';
import ArticleCard, {Article} from './ArticleCard';

interface Props {
  currentArticles: Article[];
  alert: boolean;
  setAlert: Dispatch<SetStateAction<boolean>>;
  isResponseEmpty: boolean;
}

/**
 * Displays the currently active Articles
 */
const Articles: React.FC<Props> = ({
  currentArticles,
  alert,
  setAlert,
  isResponseEmpty,
}) => {
  return (
    <>
      <div className="articles-container">
        {currentArticles.map(article => (
          <ArticleCard articleInfo={article} key={article.key} />
        ))}
      </div>
      {/* Displays when there are no articles */}
      {isResponseEmpty ? (
        <div className="no-response">
          No results fit your criteria, try again
        </div>
      ) : null}
      {/* Displays when there are no more articles to add */}
      {alert ? (
        <div
          className="alert"
          onAnimationEnd={() => setTimeout(() => setAlert(false), 2000)}>
          No more Articles
        </div>
      ) : null}
    </>
  );
};

export default Articles;
