import React, {useEffect, useState} from 'react';
import './App.css';
import axios from 'axios';
import {Article} from './Components/ArticleCard';
import Header from './Components/Header';
import Articles from './Components/Articles';
import Pagination from './Components/Pagination';
import Filters, {Params} from './Components/Filters';
import {Ripple} from 'react-spinners-css';
import {useStateCallback} from './hooks/useStateCallback';
import Footer from './Components/Footer';
const API_URL = process.env.REACT_APP_API_URL;

const App: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [articlesPerPage] = useState(9);
  const [currentPage, setcurrentPage] = useState(1);
  //*State of how many articles to retrieve
  const [articleLimit, setArticleLimit] = useState(25);
  //*State that holds index of the last queried article
  const [params, setParams] = useState<Params>({page: 1});
  //*State is true if there are no more articles
  const [alert, setAlert] = useState(false);
  //*State is true if the Filter response is empty
  const [isResponseEmpty, setIsReponseEmpty] = useState(false);

  const [offset, setOffset] = useStateCallback({offset: 0});

  //* Retrieves the next batch of articles
  const getMoreArticles = async () => {
    //* Use articleLimit if offset has been reset
    if (offset.offset == 0) {
      setOffset({offset: articleLimit * params.page}, newOffset => {
        fetchMoreArticles(newOffset.offset);
      });
      //*Otherwise use the current offset with the current limit
    } else {
      setOffset(
        {offset: offset.offset + articleLimit * params.page},
        newOffset => {
          fetchMoreArticles(newOffset.offset);
        },
      );
    }
  };

  //* Calls API for Articles
  const fetchMoreArticles = (bridge: number) => {
    setLoading(true);
    axios
      .get(API_URL!, {
        params: {
          publication: params.publication?.replace(/\s/g, '-').toLowerCase(),
          section: params.section?.replace(/\s/g, '-').toLowerCase(),
          subsection: params.subsection?.replace(/\s/g, '-').toLowerCase(),
          query: params.query?.toLowerCase(),
          page: params.page,
          limit: articleLimit,
          trim_featured_list: params.trim_featured_list,
          offset: bridge,
        },
      })
      .then(response => {
        if (response === null)
          throw new Error("Couldn't retrieve new articles");
        if (response.data.length === 0) setAlert(true);
        //*Adds the new batch to the old batch
        setArticles(articles.concat(response.data));
        //*Sets the new offset mark
        setOffset({offset: offset.offset + articleLimit}, () =>
          console.log('updated'),
        );
        setLoading(false);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    //* Function to fetch initial articles
    const getArticles = async () => {
      const response = await axios(API_URL!);

      //*Checks if a response was received
      if (response == null) {
        setLoading(false);
        throw new Error('Error occured retrieving articles');
      }

      //* Returns the data
      return response.data;
    };
    getArticles()
      .then(data => {
        if (data != null) {
          setArticles(data);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  //* Show articles on the current page
  const lastArticleIndex = currentPage * articlesPerPage;
  const firstArticleIndex = lastArticleIndex - articlesPerPage;
  const currentArticles = articles.slice(firstArticleIndex, lastArticleIndex);

  return (
    <div className="App">
      <Header />
      <div data-testid="Page" className="page-container">
        <Filters
          setLoading={setLoading}
          setArticles={setArticles}
          setParams={setParams}
          setCurrentPage={setcurrentPage}
          setIsResponseEmpty={setIsReponseEmpty}
          setArticleLimit={setArticleLimit}
          articleLimit={articleLimit}
          setOffset={setOffset}
        />
        <div className="result-container">
          {!loading ? (
            <>
              <Articles
                currentArticles={currentArticles}
                alert={alert}
                setAlert={setAlert}
                isResponseEmpty={isResponseEmpty}
              />
              <Pagination
                articlesPerPage={articlesPerPage}
                totalArticles={articles.length}
                setCurrentPage={setcurrentPage}
                currentPage={currentPage}
                getMoreArticles={getMoreArticles}
              />
            </>
          ) : (
            <Ripple color="#00008b" size={100} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
