import React, {Dispatch, SetStateAction} from 'react';

interface Props {
  articlesPerPage: number;
  totalArticles: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  getMoreArticles: () => void;
}
/**
 * Used as navigation to the Article Pages
 */
const Pagination: React.FC<Props> = ({
  articlesPerPage,
  totalArticles,
  setCurrentPage,
  currentPage,
  getMoreArticles,
}) => {
  const pageNumbers = [];

  //* Populates array with the total number of pages
  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map(number => (
          <li
            key={number}
            className={
              (currentPage == number ? 'active-page ' : '') +
              'pagination-number'
            }
            //* Switches the given page
            onClick={() => {
              setCurrentPage(number);
              window.scrollTo({top: 0, behavior: 'smooth'});
            }}>
            {number}
          </li>
        ))}
        {currentPage === Math.ceil(totalArticles / articlesPerPage) ? (
          <button className="pagination-more" onClick={() => getMoreArticles()}>
            Load More
          </button>
        ) : null}
      </ul>
    </nav>
  );
};

export default Pagination;
